import React, { useState, useEffect } from 'react';
import './HowItWorksPopup.css';

const HowItWorksPopup = () => {
  const [closePopup, setClosePopup] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Check for mobile devices
    if (/android|iphone|ipad|ipod/i.test(userAgent)) {
      setIsMobile(true);
    }
  }, []);

  if (isMobile || closePopup) return null;

  return (
    <div className="popup-overlay">
      <div className="how-it-works-popup">
        <h2 className="popup-title">4 steps to create your FREE JiveBird!</h2>

        <div className="popup-step">
          <div className="step-number">1.</div>
          <div className="step-content">
            <p className="step-description">Choose a card or a photo.</p>
          </div>
        </div>

        <div className="popup-step">
          <div className="step-number">2.</div>
          <div className="step-content">
            <p className="step-description">
              Mix and match a video, a song, a voice message, or add an optional gift card.
            </p>
          </div>
        </div>

        <div className="popup-step">
          <div className="step-number">3.</div>
          <div className="step-content">
            <p className="step-description">
              Check you have created the perfect JiveBird.
            </p>
          </div>
        </div>

        <div className="popup-step">
          <div className="step-number">4.</div>
          <div className="step-content">
            <p className="step-description">
              Schedule your JiveBird to arrive instantly or on the special day.
            </p>
          </div>
        </div>

        <div className="button-div">
          <button className="popup-button" onClick={() => setClosePopup(true)}>
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default HowItWorksPopup;
